import React from 'react'
import { Button, Col, Row, Statistic } from 'antd'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import './Cards.css'


// const { Countdown } = Statistic;
// const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30; // Moment is also OK

export default () =>{
    const data = useStaticQuery ( graphql`
    fragment cardImage on File {
        childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
            }
        }
    }
    query cardimageQuery {
        card1: file(relativePath: { eq: "homepage_images/card_images/homepage_pic1.jpg" }) {
            ...cardImage
        }
        card2: file(relativePath: { eq: "homepage_images/card_images/homepage_pic2.jpeg" }) {
            ...cardImage
        }
        card3: file(relativePath: { eq: "homepage_images/card_images/homepage_pic3.jpeg" }) {
            ...cardImage
        }
    }
    `)
    return(
    <div className='cardsContainer'>
        <div className='cardsDesktop'>
        <Row className='cardsRowDesktop1' align='middle'>
        <Col span={6} offset={4}>
            <h2>實體主日崇拜</h2>
            <p>疫情期間，教會採取了嚴格的防疫措施盡力保障大家的健康和安全，鼓勵大家進入教堂時配戴口罩。若您方便，歡迎您來到我們教會一起敬拜讚美主。</p>
            <p><b>時間</b>：每周日上午10點</p>
            <p><b>地點</b>：中華福音教會 35301 Five Mile Rd, Livonia MI 48154</p>
        </Col>
        <Col span={6} offset={4}>
            <h2>線上主日崇拜</h2>
            <p>您也可通過線上方式加入Zoom會議，和我們一起敬拜讚美主。您可以直接點擊下方“加入”按鈕進入會議，並輸入下方提供的Code。</p>
            <p><b>時間</b>：每周日上午10點</p>
            <p><b>地點</b>：線上Zoom(Code:378192)</p>
            <Button type="primary" href="https://zoom.us/j/250518046" style={{ background: "#004e66", borderColor: "#004e66" }}>加入</Button>
        </Col>
        </Row>
        {/* <Row>
            <div className='Counter'>
                <h2>距离下一个活动还有</h2>
            <Countdown valueStyle={{color: '#004e66'}} value={deadline} format="D 天 H 时 m 分 s 秒" />
            </div>        
        </Row> */}
        <Row className='cardsRowDesktop2' align='middle'>
            <Col span={6} offset={4}>
                <BackgroundImage fluid={data.card2.childImageSharp.fluid} className='card2Background'>
                    <div className='card2Container'>
                        <h2 className='card2Content'>復活節慶祝活動</h2>
                    </div>
                </BackgroundImage>
            </Col>
            <Col span={6} offset={4}>
                <BackgroundImage fluid={data.card3.childImageSharp.fluid} className='card3Background'>
                    <div className='card3Container'>
                        <h2 className='card3Content'>每日經文</h2>
                        <Button type="primary" href="https://traditional-odb.org/today/" style={{ background: "#fcbe32", borderColor: "#fcbe32" }}>更多</Button>
                    </div>
                </BackgroundImage>
            </Col>
        </Row>
        </div>
        <div className='cardsMobile'>
        <Row className='cardsRowMobile1' align='middle'>
        <Col span={20} offset={2}>
            <h2>實體主日崇拜</h2>
            <p>疫情期間，教會採取了嚴格的防疫措施盡力保障大家的健康和安全，鼓勵大家進入教堂時配戴口罩。若您方便，歡迎您來到我們教會一起敬拜讚美主。</p>
            <p><b>時間</b>：每周日上午10點</p>
            <p><b>地點</b>：中華福音教會35301 Five Mile Rd, Livonia MI 48154</p>
        </Col>
        </Row>
        <Row className='cardsRowMobile2' align='middle'>
        <Col span={20} offset={2}>
            <h2>線上主日崇拜</h2>
            <p>您也可通過線上方式加入Zoom會議，和我們一起敬拜讚美主。您可以直接點擊下方“加入”按鈕進入會議，並輸入下方提供的Code。</p>
            <p><b>時間</b>：每周日上午10點</p>
            <p><b>地點</b>：線上Zoom(Code:378192)</p>
            <Button type="primary" href="https://zoom.us/j/250518046" style={{ background: "#004e66", borderColor: "#004e66" }}>加入</Button>
        </Col>
        </Row>
        {/* <Row>
            <div className='Counter'>
            <h2>距离下一个活动还有</h2>
            <Countdown value={deadline} valueStyle={{color: '#004e66'}} format="D 天 H 时 m 分 s 秒" />
            </div>
        </Row> */}
        <Row className='cardsRowMobile3' align='middle'>
            <Col span={20} offset={2}>
                <BackgroundImage fluid={data.card2.childImageSharp.fluid} className='card2Background'>
                    <div className='card2Container'>
                        <h2 className='card2Content'>復活節慶祝活動</h2>
                        <Button type="primary" style={{ background: "#fcbe32", borderColor: "#fcbe32" }}>更多</Button>
                    </div>
                </BackgroundImage>
            </Col>
        </Row>
        <Row></Row>
        <Row className='cardsRowMobile4' align='middle'>
            <Col span={20} offset={2}>
                <BackgroundImage fluid={data.card3.childImageSharp.fluid} className='card3Background'>
                    <div className='card3Container'>
                        <h2 className='card3Content'>每日經文</h2>
                        <Button type="primary" style={{ background: "#fcbe32", borderColor: "#fcbe32" }}>更多</Button>
                    </div>
                </BackgroundImage>
            </Col>
        </Row>
        </div>
    </div>
    )
}