import React from 'react'
import Navbar from '../components/NavBar/NavBar'
import Header from '../components/Header/Header'
import Cards from '../components/Cards/Cards'
import GlobalFooter from '../components/Footer/GlobalFooter'

export default () => {
    return (
      <div>
        <div>
          <Navbar />
        </div>
        <div>
          <Header />
        </div>
        <div>
          <Cards />
        </div>
        <div>
          <GlobalFooter />
        </div>
      </div>
      )
    }