import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { Col, Row } from 'antd'
import BackgroundImage from 'gatsby-background-image'
import './Header.css'

export default () => {
    const data = useStaticQuery ( graphql`
    query HeaderQuery {
      file(relativePath: {eq: "homepage_images/header.jpg"}) {
        childImageSharp {
          fluid(maxWidth:2000,quality:100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
    `)
    return(
    <div>
        <div className="header_Container">
            <BackgroundImage
            fluid={data.file.childImageSharp.fluid}
            className='header_Background'>
                <div className='welcome_Title'>
                    <h1>連 於 基 督 &nbsp; 彼 此 相 愛</h1>
                </div>
            </BackgroundImage>
        </div>
        {/* <div className='ourMission'>
          <Row>
            <Col span={16} offset={4}>
            <p>中华福音教会是由密西根地区，相信主耶稣的悔改福音，用他宝血所买赎来的人组成，一同寻求神的带领，分享在神面前的喜乐与事工，并同心合意兴旺福音，见证主的救恩，荣耀神的圣名。</p>
            <p className='signature'>- 中华福音教会同工</p>
            </Col>
          </Row>
        </div> */}
    </div>
    )   
}